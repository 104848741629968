import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import './App.css';
import Routes from './routes';

function App() {
  const PreLoader = () => {
    return (<>
      <div className="page page-center">
        <div className="container container-slim py-4">
          <div className="text-center">
            <div className="mb-3">
              <a href="." className="navbar-brand navbar-brand-autodark"><img src="./static/logo-small.svg" height="36" alt="" /></a>
            </div>
            <div className="text-secondary mb-3">Preparing application</div>
            <div className="progress progress-sm">
              <div className="progress-bar progress-bar-indeterminate"></div>
            </div>
          </div>
        </div>
      </div>
    </>)
  }

  return (<>
    <React.Suspense fallback={<PreLoader />}>
      <Router>
        <Routes />
      </Router>
    </React.Suspense>
  </>);
}

export default App;

import { lazy } from "react";
import { useRoutes, Navigate, Outlet } from "react-router-dom";

const Layout = lazy(() => import('./layout/index'))
const Login = lazy(() => import('./views/auth/login'))
const Dashboard = lazy(() => import('./views/dashboard/index'))
const Staff = lazy(() => import('./views/staff/index'))
const Product = lazy(() => import('./views/product/index'))
const ProductDetail = lazy(() => import('./views/product/details'))
const CollectionProducts = lazy(()=> import('./views/product/collections'))
const Category = lazy(() => import('./views/category/index'))
const Order = lazy(() => import('./views/order/index'))
const OrderDetails = lazy(()=> import('./views/order/details'))
const OrderHistory = lazy(()=> import('./views/order/history'))
const Customer = lazy(() => import('./views/customer/index'))
const CustomerDetail = lazy(() => import('./views/customer/details'))
const Stock = lazy(()=> import('./views/stock/index'))

const PageNotFound = () => {
    return (<>
        <div className="page page-center">
            <div className="container-tight py-4">
                <div className="empty">
                    <div className="empty-header">404</div>
                    <p className="empty-title">Oops… You just found an error page</p>
                    <p className="empty-subtitle text-secondary">
                        We are sorry but the page you are looking for was not found
                    </p>
                    <div className="empty-action">
                        <a href="/login" className="btn btn-primary">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M5 12l14 0"></path><path d="M5 12l6 6"></path><path d="M5 12l6 -6"></path></svg>
                            Take me home
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

const PageError = () => {
    return (<>
        <div className="page page-center">
            <div className="container-tight py-4">
                <div className="empty">
                    <div className="empty-header">500</div>
                    <p className="empty-title">Oops… You just found an error page</p>
                    <p className="empty-subtitle text-secondary">
                        We are sorry but our server encountered an internal error
                    </p>
                    <div className="empty-action">
                        <a href="/login" className="btn btn-primary">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M5 12l14 0"></path><path d="M5 12l6 6"></path><path d="M5 12l6 -6"></path></svg>
                            Take me home
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default function Routes() {
    var userRole = localStorage.getItem('x-access-role') ?? '';
    let routes = useRoutes([
        { path: '', element: <Navigate to='/login' /> },
        { path: 'login', element: <Login /> },
        { path: '*', element: <PageNotFound /> },
        { path: 'errors', element: <PageError /> },
        {
            path: 'app', element: <Layout />,
            children: [
                { path: 'dashboard', element: <Dashboard /> },
                { path: 'products', element: <Product /> },
                { path: 'products/:collectionName/:collectionId', element: <CollectionProducts /> },
                { path: 'products/:productId', element: <ProductDetail /> },
                { path: 'collections', element: <Category /> },
                { path: 'orders', element: <Order /> },
                { path: 'orders/history', element: <OrderHistory /> },
                { path: 'orders/:orderId', element: <OrderDetails /> },
                { path: 'staffs', element: <Staff /> },
                { path: 'customers', element: <Customer /> },
                { path: 'customers/:customerId', element: <CustomerDetail /> },
                { path: 'stocks', element: <Stock /> },
            ]
        }
    ]);
    return routes;
};